
  import React from 'react';
  import { getWidgetWrapper } from '@wix/yoshi-flow-editor/runtime/esm/WidgetWrapper.js';
  import Widget from '/home/builduser/work/7de8fbf09bf2a439/packages/bookings-form-widget/src/components/BookingsForm/Widget/index.tsx';

  import {
    withStyles
  } from '@wix/native-components-infra';

  

  import { initI18n as initI18n } from '@wix/yoshi-flow-editor/runtime/esm/i18next/init';


  
import { createExperiments, createWidgetExperiments } from '@wix/yoshi-flow-editor/runtime/esm/experiments';
    

  
import { I18nextProvider } from '@wix/wix-i18n-config';


      import { PureExperimentsProvider } from '@wix/wix-experiments-react';
      var ExperimentsProvider = React.Fragment;
      

import { BILoggerProvider } from '@wix/yoshi-flow-editor/runtime/esm/react/BILogger/BILoggerProvider';

import { FedopsLoggerProvider } from '@wix/yoshi-flow-editor/runtime/esm/react/FedopsLoggerProvider';

import { HttpProvider } from '@wix/yoshi-flow-editor';

var providers = {
  I18nextProvider,
  ExperimentsProvider,
  PureExperimentsProvider,
  BILoggerProvider,
  FedopsLoggerProvider,
  HttpProvider,
}


  import stylesParams from '/home/builduser/work/7de8fbf09bf2a439/packages/bookings-form-widget/src/components/BookingsForm/stylesParams.ts';

  var sentryConfig = {
      DSN: 'https://8a26eadef7db4e1f8c80ebbe1517ed8e@sentry.wixpress.com/1627',
      id: '8a26eadef7db4e1f8c80ebbe1517ed8e',
      projectName: 'bookings-form-widget',
      teamName: 'bookings',
    };

  var translationsConfig = {"icuEnabled":true,"defaultTranslationsPath":"/home/builduser/work/7de8fbf09bf2a439/packages/bookings-form-widget/src/assets/locales/messages_en.json","availableLanguages":["ar","bg","ca","cs","da","de","el","en","es","fi","fr","he","hi","hu","id","it","ja","ko","lt","ms","nl","no","pl","pt","ro","ru","sk","sl","sv","th","tl","tr","uk","vi","zh"]};

  var UserComponent = getWidgetWrapper({
      initI18n,
      withStylesHoc: withStyles,
      createExperiments,
      createWidgetExperiments,
      providers,
    }, Widget, {
    sentryConfig,
    translationsConfig,
    stylesParams,
    componentId: '985e6fc8-ce3f-4cf8-9b85-714c73f48695',
    name: 'BookingsForm',
    withErrorBoundary: false,
    localeDistPath: 'assets/locales',
  });

  
    import { hot } from '@wix/component-hot-loader';
    UserComponent = hot(module, UserComponent);
  

  const loadChunks = Widget.loadChunks;

  export default {
    component: UserComponent,
    loadChunks
  };

export enum TabsTranslationsKeys {
  Manage = 'app.settings.tabs.manage',
  Layout = 'app.settings.tabs.layout',
  Display = 'app.settings.tabs.display',
  Design = 'app.settings.tabs.design',
  Text = 'app.settings.tabs.text',
}

export enum TabsDataHooks {
  Manage = 'manage-tab-button',
  Layout = 'layout-tab-button',
  Display = 'display-tab-button',
  Design = 'design-tab-button',
  Text = 'text-tab-button',
  Support = 'support-tab-button',
  Premium = 'premium-tab-button',
}

export enum SettingsTab {
  Manage = 'manage',
  Layout = 'layout',
  Display = 'display',
  Design = 'design',
  Text = 'text',
  Support = 'support',
}

export enum DesignTabSubSettings {
  STYLES = 'styles',
  TEXT = 'text',
  BUTTON = 'button',
}

export enum AlignmentOptions {
  LEFT = 'left',
  RIGHT = 'right',
  CENTER = 'center',
}

export enum BorderStyle {
  UNDERLINE = 'underline',
  BOX = 'box',
}

export enum PaymentMethod {
  MEMBERSHIP = 'membership',
  SINGLE = 'single',
}

import React, { FC } from 'react';
import { WidgetProps } from '@wix/yoshi-flow-editor';
import { withWidgetWCS } from '@wix/yoshi-flow-editor/wcs/wrappers';
import settingsParams from '../settingsParams';
import stylesParams from '../stylesParams';
import { st, classes } from './Widget.st.css';
import { FormActions } from '../Actions/actions';
import { FormViewModel } from '../ViewModel/viewModel';

export type ControllerProps = FormActions & FormViewModel;

const Widget: FC<WidgetProps<ControllerProps>> = () => {
  return (
    <div className={st(classes.root, {})} data-hook="BookingsForm-wrapper">
      <div className={classes.backButton}>Back</div>
      <div className={classes.body}>
        <div className={classes.form}>Form</div>
        <div className={classes.paymentDetails}>Payment Details</div>
      </div>
    </div>
  );
};

export default Widget;
export const BookingsFormWCS = withWidgetWCS(
  Widget,
  settingsParams,
  stylesParams,
);

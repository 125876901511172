import {
  BorderStyle,
  AlignmentOptions,
  PaymentMethod,
} from '../../types/types';
import {
  createSettingsParams,
  SettingsParamType,
} from '@wix/yoshi-flow-editor/tpa-settings';

export type ISettingsParams = {
  titlesAlignment: AlignmentOptions;
  fieldsBorderStyle: BorderStyle;
  stretchButtonToFullWidth: SettingsParamType.Boolean;
  textTabTitle: SettingsParamType.Text;
  textTabSubTitle: SettingsParamType.Text;
  participantsTitle: SettingsParamType.Text;
  paymentMethodTitle: SettingsParamType.Text;
  pricingPlanTitle: SettingsParamType.Text;
  buttonTextTitle: SettingsParamType.Text;
  buttonOnlinePaymentTextTitle: SettingsParamType.Text;
  buttonRequestBookingTextTitle: SettingsParamType.Text;
  videoConferenceBadgeVisibility: SettingsParamType.Boolean;
  paymentMethod: PaymentMethod;
};

export default createSettingsParams<ISettingsParams>({
  titlesAlignment: {
    getDefaultValue: ({ isRTL }): AlignmentOptions =>
      isRTL ? AlignmentOptions.RIGHT : AlignmentOptions.LEFT,
  },
  fieldsBorderStyle: {
    getDefaultValue: () => BorderStyle.BOX,
  },
  stretchButtonToFullWidth: {
    type: SettingsParamType.Boolean,
    getDefaultValue: () => true,
  },
  textTabTitle: {
    type: SettingsParamType.Text,
    getDefaultValue: () => '',
  },
  textTabSubTitle: {
    type: SettingsParamType.Text,
    getDefaultValue: () => '',
  },
  participantsTitle: {
    type: SettingsParamType.Text,
    getDefaultValue: () => '',
  },
  paymentMethodTitle: {
    type: SettingsParamType.Text,
    getDefaultValue: () => '',
  },
  pricingPlanTitle: {
    type: SettingsParamType.Text,
    getDefaultValue: () => '',
  },
  buttonTextTitle: {
    type: SettingsParamType.Text,
    getDefaultValue: () => '',
  },
  buttonOnlinePaymentTextTitle: {
    type: SettingsParamType.Text,
    getDefaultValue: () => '',
  },
  buttonRequestBookingTextTitle: {
    type: SettingsParamType.Text,
    getDefaultValue: () => '',
  },
  videoConferenceBadgeVisibility: {
    type: SettingsParamType.Boolean,
    getDefaultValue: () => true,
  },
  paymentMethod: {
    getDefaultValue: () => PaymentMethod.MEMBERSHIP,
  },
});
